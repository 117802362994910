var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      !_vm.load
        ? _c(
            "div",
            [
              !_vm.allowNegotiation
                ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`payments.titles.negotiationBlocked`)) +
                        " "
                    ),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `payments.enumNegotiationAllow.${_vm.reason}`
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-card",
        { attrs: { "body-class": "p-0" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("payments.titles.negotiations")) +
                          " "
                      ),
                    ]),
                  ]),
                  !_vm.load
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-right mt-1",
                          attrs: { cols: "4" },
                        },
                        [
                          _vm.findGroups("ACL:BUTTON:NEGOTIATION") &&
                          _vm.allowNegotiation
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "success",
                                    "data-test": "btn-negotiations-new",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.newNegotiation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.buttons.newNegotiation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-body",
            { staticClass: "p-0 wallet-table-format" },
            [
              _c("driver-negotiation-table", {
                attrs: { driverId: _vm.driverId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }