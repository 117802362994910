<template>
  <div class="animated fadeIn">
    <div v-if="!load">
      <b-alert show variant="danger" v-if="!allowNegotiation">
        {{ $t(`payments.titles.negotiationBlocked`) }}
        <strong>
          {{ $t(`payments.enumNegotiationAllow.${reason}`) }}
        </strong>
      </b-alert>
    </div>
    <b-card body-class="p-0">
      <b-card-header>
        <b-row>
          <b-col cols="8">
            <h4 class="mt-2">
              {{ $t('payments.titles.negotiations') }}
            </h4>
          </b-col>
          <b-col
            v-if="!load"
            cols="4"
            class="text-right mt-1"
          >
            <b-button
              v-if="findGroups('ACL:BUTTON:NEGOTIATION') && allowNegotiation"
              variant="success"
              data-test="btn-negotiations-new"
              @click="newNegotiation()"
            >
              {{ $t('payments.buttons.newNegotiation') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="p-0 wallet-table-format">
        <driver-negotiation-table
          :driverId="driverId"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import NEGOTIATION_ALLOW from '@graphql/negotiation/queries/getNegotiationAllow.gql';
import { mapGetters } from 'vuex'
import { makeQuery } from '@graphql/middleware'
export default {
  name: 'Negotiations',
  components: {
    DriverNegotiationTable: () => import('@components/drivers/payment-info/driver-negotiation-table'),
  },
  data() {
    return {
      driverId: this.$route.params.id,
      reason: '',
      allowNegotiation: true,
      load: true,
    }
  },
  computed: {
    ...mapGetters('user', ['findGroups']),
  },
  mounted () {
    this.getAllow()
  },
  methods: {
    newNegotiation () {
      this.$router.push('negotiations/new')
    },
    getAllow () {
      this.load = true
      makeQuery(
        NEGOTIATION_ALLOW,
        { 
          driver: this.$route.params.id
        }
      )
      .then((res) => {
        const { getAllowNegotiation } = res.data
        this.reason = getAllowNegotiation.reason
        this.allowNegotiation = getAllowNegotiation.result
        this.load = false
      })
      .catch((err) => {
        this.$swal({
          type: 'error',
          title: this.$t('payments.titles.errorNegotiationAllow'),
          showConfirmButton: false,
          timer: 2500,
        })
        console.error(err)
      })
    }
  }
};
</script>

<style>
.wallet-table-format .custom-data-table .card {
  border: none;
  border-top: 1px solid #c8ced3;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.wallet-table-format .custom-data-table .custom-data-table__filters {
  margin: 20px 20px 10px 20px !important;
}

.payment-method__icon {
  width: 100%;
  text-align: center;
  font-size: 18pt;
  color: #666666;
}
</style>
